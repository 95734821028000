.app {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  text-align: center;
}

.app-body {
  align-items: center;
  background-color: white;
  color: #282c34;
  display: flex;
  flex-direction: column;
  font-size: calc(8px + 2vmin);
  height: 100vh;
  justify-content: center;
}

.app-footer {
  align-items: center;
  background-color: #898c94;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: calc(8px + 2vmin);
  height: 20vh;
  justify-content: center;
}

.app-header {
  align-items: center;
  background-color: #a6bbf2;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: calc(8px + 2vmin);
  height: 10vh;
  justify-content: center;
}