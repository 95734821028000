.center {
    justify-content: center;
}

.col {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
}